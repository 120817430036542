import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5189352c = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _449a2f01 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _f046048c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _e8c19280 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _6316decd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _dbe9c96e = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _a5b1c6b4 = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _2588f06a = () => interopDefault(import('../pages/dental-problems.vue' /* webpackChunkName: "pages/dental-problems" */))
const _4c8d34b5 = () => interopDefault(import('../pages/quality-guarantee.vue' /* webpackChunkName: "pages/quality-guarantee" */))
const _5457885e = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _52029f34 = () => interopDefault(import('../pages/appointment-booking.vue' /* webpackChunkName: "pages/appointment-booking" */))
const _dd9592de = () => interopDefault(import('../pages/implant-oral-surgery.vue' /* webpackChunkName: "pages/implant-oral-surgery" */))
const _3c7724c0 = () => interopDefault(import('../pages/invisalign-orthodontic-treatment.vue' /* webpackChunkName: "pages/invisalign-orthodontic-treatment" */))
const _f103d95a = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _5e078741 = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _2baa134a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _a8f85786 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3d4cb30c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _55780d06 = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _2a97c614 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _5f9e0fd1 = () => interopDefault(import('../pages/premium-dental-care.vue' /* webpackChunkName: "pages/premium-dental-care" */))
const _dfb38b6a = () => interopDefault(import('../pages/account/referral-program.vue' /* webpackChunkName: "pages/account/referral-program" */))
const _65cbb59a = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _2201cbaa = () => interopDefault(import('../pages/account/appointments.vue' /* webpackChunkName: "pages/account/appointments" */))
const _50a12ead = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _5bce971e = () => interopDefault(import('../pages/about/introduction.vue' /* webpackChunkName: "pages/about/introduction" */))
const _14f36fd8 = () => interopDefault(import('../pages/about/career/index.vue' /* webpackChunkName: "pages/about/career/index" */))
const _73f4cb95 = () => interopDefault(import('../pages/about/trainings/index.vue' /* webpackChunkName: "pages/about/trainings/index" */))
const _6e76b9ca = () => interopDefault(import('../pages/about/dentists/index.vue' /* webpackChunkName: "pages/about/dentists/index" */))
const _339f21ea = () => interopDefault(import('../pages/about/testimonials.vue' /* webpackChunkName: "pages/about/testimonials" */))
const _0c585ed8 = () => interopDefault(import('../pages/about/career/preview/_job_preview.vue' /* webpackChunkName: "pages/about/career/preview/_job_preview" */))
const _3fb8f804 = () => interopDefault(import('../pages/about/trainings/preview/_training_preview.vue' /* webpackChunkName: "pages/about/trainings/preview/_training_preview" */))
const _5a0d1b68 = () => interopDefault(import('../pages/about/career/_job.vue' /* webpackChunkName: "pages/about/career/_job" */))
const _63577d1c = () => interopDefault(import('../pages/about/dentists/_dentist.vue' /* webpackChunkName: "pages/about/dentists/_dentist" */))
const _25153b48 = () => interopDefault(import('../pages/about/notifications/_notification.vue' /* webpackChunkName: "pages/about/notifications/_notification" */))
const _4c0b43fc = () => interopDefault(import('../pages/about/trainings/_training.vue' /* webpackChunkName: "pages/about/trainings/_training" */))
const _4028ef22 = () => interopDefault(import('../pages/blog/preview/_article_preview.vue' /* webpackChunkName: "pages/blog/preview/_article_preview" */))
const _cf4841de = () => interopDefault(import('../pages/about/_content.vue' /* webpackChunkName: "pages/about/_content" */))
const _ee39d502 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _cb42c7f6 = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _9fcd68b2 = () => interopDefault(import('../pages/services/_service/_price.vue' /* webpackChunkName: "pages/services/_service/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _5189352c,
    name: "privacy-policy___hu"
  }, {
    path: "/araink",
    component: _449a2f01,
    name: "pricing___hu"
  }, {
    path: "/blog",
    component: _f046048c,
    name: "blog___hu"
  }, {
    path: "/elfelejtett-jelszo",
    component: _e8c19280,
    name: "forgot-password___hu"
  }, {
    path: "/en",
    component: _6316decd,
    name: "index___en"
  }, {
    path: "/felhasznalasi-feltetelek",
    component: _dbe9c96e,
    name: "terms-and-conditions___hu"
  }, {
    path: "/fizetesi-lehetosegek",
    component: _a5b1c6b4,
    name: "payment-methods___hu"
  }, {
    path: "/fogaszati-panaszok",
    component: _2588f06a,
    name: "dental-problems___hu"
  }, {
    path: "/garancia",
    component: _4c8d34b5,
    name: "quality-guarantee___hu"
  }, {
    path: "/gyakran-ismetelt-kerdesek",
    component: _5457885e,
    name: "faq___hu"
  }, {
    path: "/idopontfoglalas",
    component: _52029f34,
    name: "appointment-booking___hu"
  }, {
    path: "/implantatum-szajsebeszet",
    component: _dd9592de,
    name: "implant-oral-surgery___hu"
  }, {
    path: "/invisalign-fogszabalyozas",
    component: _3c7724c0,
    name: "invisalign-orthodontic-treatment___hu"
  }, {
    path: "/jelszovaltoztatas",
    component: _f103d95a,
    name: "change-password___hu"
  }, {
    path: "/jogi-nyilatkozat",
    component: _5e078741,
    name: "legal-notice___hu"
  }, {
    path: "/kapcsolat",
    component: _2baa134a,
    name: "contact___hu"
  }, {
    path: "/kereses",
    component: _a8f85786,
    name: "search___hu"
  }, {
    path: "/leiratkozas",
    component: _3d4cb30c,
    name: "unsubscribe___hu"
  }, {
    path: "/munkaink",
    component: _55780d06,
    name: "works___hu"
  }, {
    path: "/szolgaltatasok",
    component: _2a97c614,
    name: "services___hu"
  }, {
    path: "/en/appointment-booking",
    component: _52029f34,
    name: "appointment-booking___en"
  }, {
    path: "/en/blog",
    component: _f046048c,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _f103d95a,
    name: "change-password___en"
  }, {
    path: "/en/contact",
    component: _2baa134a,
    name: "contact___en"
  }, {
    path: "/en/dental-problems",
    component: _2588f06a,
    name: "dental-problems___en"
  }, {
    path: "/en/faq",
    component: _5457885e,
    name: "faq___en"
  }, {
    path: "/en/forgot-password",
    component: _e8c19280,
    name: "forgot-password___en"
  }, {
    path: "/en/invisalign-orthodontic-treatment",
    component: _3c7724c0,
    name: "invisalign-orthodontic-treatment___en"
  }, {
    path: "/en/legal-notice",
    component: _5e078741,
    name: "legal-notice___en"
  }, {
    path: "/en/payment-methods",
    component: _a5b1c6b4,
    name: "payment-methods___en"
  }, {
    path: "/en/premium-dental-care",
    component: _5f9e0fd1,
    name: "premium-dental-care___en"
  }, {
    path: "/en/pricing",
    component: _449a2f01,
    name: "pricing___en"
  }, {
    path: "/en/privacy-policy",
    component: _5189352c,
    name: "privacy-policy___en"
  }, {
    path: "/en/quality-guarantee",
    component: _4c8d34b5,
    name: "quality-guarantee___en"
  }, {
    path: "/en/search",
    component: _a8f85786,
    name: "search___en"
  }, {
    path: "/en/services",
    component: _2a97c614,
    name: "services___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _dbe9c96e,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/unsubscribe",
    component: _3d4cb30c,
    name: "unsubscribe___en"
  }, {
    path: "/en/works",
    component: _55780d06,
    name: "works___en"
  }, {
    path: "/profilom/ajalnasok",
    component: _dfb38b6a,
    name: "account-referral-program___hu"
  }, {
    path: "/profilom/beallitasok",
    component: _65cbb59a,
    name: "account-settings___hu"
  }, {
    path: "/profilom/idopontjaim",
    component: _2201cbaa,
    name: "account-appointments___hu"
  }, {
    path: "/profilom/vezerlopult",
    component: _50a12ead,
    name: "account-dashboard___hu"
  }, {
    path: "/rolunk/bemutatkozas",
    component: _5bce971e,
    name: "about-introduction___hu"
  }, {
    path: "/rolunk/karrier",
    component: _14f36fd8,
    name: "about-career___hu"
  }, {
    path: "/rolunk/kepzeseink",
    component: _73f4cb95,
    name: "about-trainings___hu"
  }, {
    path: "/rolunk/orvosaink",
    component: _6e76b9ca,
    name: "about-dentists___hu"
  }, {
    path: "/rolunk/velemenyek",
    component: _339f21ea,
    name: "about-testimonials___hu"
  }, {
    path: "/en/about/career",
    component: _14f36fd8,
    name: "about-career___en"
  }, {
    path: "/en/about/dentists",
    component: _6e76b9ca,
    name: "about-dentists___en"
  }, {
    path: "/en/about/introduction",
    component: _5bce971e,
    name: "about-introduction___en"
  }, {
    path: "/en/about/testimonials",
    component: _339f21ea,
    name: "about-testimonials___en"
  }, {
    path: "/en/about/trainings",
    component: _73f4cb95,
    name: "about-trainings___en"
  }, {
    path: "/en/account/appointments",
    component: _2201cbaa,
    name: "account-appointments___en"
  }, {
    path: "/en/account/dashboard",
    component: _50a12ead,
    name: "account-dashboard___en"
  }, {
    path: "/en/account/referral-program",
    component: _dfb38b6a,
    name: "account-referral-program___en"
  }, {
    path: "/en/account/settings",
    component: _65cbb59a,
    name: "account-settings___en"
  }, {
    path: "/en/about/career/preview/:job_preview",
    component: _0c585ed8,
    name: "about-career-preview-job_preview___en"
  }, {
    path: "/en/about/trainings/preview/:training_preview",
    component: _3fb8f804,
    name: "about-trainings-preview-training_preview___en"
  }, {
    path: "/en/about/career/:job",
    component: _5a0d1b68,
    name: "about-career-job___en"
  }, {
    path: "/en/about/dentists/:dentist",
    component: _63577d1c,
    name: "about-dentists-dentist___en"
  }, {
    path: "/en/about/notifications/:notification",
    component: _25153b48,
    name: "about-notifications-notification___en"
  }, {
    path: "/en/about/trainings/:training",
    component: _4c0b43fc,
    name: "about-trainings-training___en"
  }, {
    path: "/en/blog/preview/:article_preview",
    component: _4028ef22,
    name: "blog-preview-article_preview___en"
  }, {
    path: "/rolunk/karrier/preview/:job_preview",
    component: _0c585ed8,
    name: "about-career-preview-job_preview___hu"
  }, {
    path: "/rolunk/kepzeseink/preview/:training_preview",
    component: _3fb8f804,
    name: "about-trainings-preview-training_preview___hu"
  }, {
    path: "/blog/preview/:article_preview",
    component: _4028ef22,
    name: "blog-preview-article_preview___hu"
  }, {
    path: "/en/about/:content",
    component: _cf4841de,
    name: "about-content___en"
  }, {
    path: "/en/blog/:article",
    component: _ee39d502,
    name: "blog-article___en"
  }, {
    path: "/en/services/:service",
    component: _cb42c7f6,
    name: "services-service___en"
  }, {
    path: "/rolunk/ertesitesek/:notification",
    component: _25153b48,
    name: "about-notifications-notification___hu"
  }, {
    path: "/rolunk/karrier/:job",
    component: _5a0d1b68,
    name: "about-career-job___hu"
  }, {
    path: "/rolunk/kepzeseink/:training",
    component: _4c0b43fc,
    name: "about-trainings-training___hu"
  }, {
    path: "/rolunk/orvosaink/:dentist",
    component: _63577d1c,
    name: "about-dentists-dentist___hu"
  }, {
    path: "/en/services/:service/:price",
    component: _9fcd68b2,
    name: "services-service-price___en"
  }, {
    path: "/blog/:article",
    component: _ee39d502,
    name: "blog-article___hu"
  }, {
    path: "/rolunk/:content",
    component: _cf4841de,
    name: "about-content___hu"
  }, {
    path: "/szolgaltatasok/:service",
    component: _cb42c7f6,
    name: "services-service___hu"
  }, {
    path: "/szolgaltatasok/:service/:price",
    component: _9fcd68b2,
    name: "services-service-price___hu"
  }, {
    path: "/",
    component: _6316decd,
    name: "index___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
